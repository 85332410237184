@import '../../../../sass/variables';

.button {
  @apply w-full block hover:opacity-90 text-white rounded-lg px-4 py-3 mt-9 sm:mt-16;

  background-color: $primary;
  border: solid 2px $primary;

  &:disabled {
    @apply opacity-90;

    cursor: not-allowed;
  }
}

.button--secondary {
  @apply w-full block hover:opacity-90 bg-white rounded-lg px-4 py-3 mt-9 sm:mt-16;

  color: $primary;
  border: solid 2px $primary;
  font-weight: 700;

  &:disabled {
    @apply opacity-90;

    cursor: not-allowed;
  }
}

.input-container {
  @apply mt-6 sm:mt-9;

  .label {
    @apply block font-bold;

    color: $black;
  }

  .input {
    @apply w-full p-4 rounded-lg bg-white mt-2;

    box-shadow: $shadow;

    &::placeholder {
      color: $complementary-1;
    }

    &:focus {
      @apply outline-none;
    }
  }
}

.editable-field {
  &__type-title {
    cursor: pointer;
    font-weight: 500;
    font-size: 1.875rem;
    line-height: 2.25rem;
    padding-block: 5px;
    width: 100%;
  }

  &__type-paragraph {
    appearance: none;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0.25em;
    margin: 0;
    height: 10rem;
    resize: none;
    width: 100%;
  }

  .is-editing {
    display: none;
    background-color: red;
  }
}

.error {
  @apply mt-1 text-red-600;
}
