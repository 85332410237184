@import 'sass/variables';

.footer {
  background-color: $footer-background;
  color: $grey-text;
  font-size: $font-small;
  height: fit-content;
  padding: 2rem;
  width: 100%;
  z-index: $z-1;
}

.container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  height: 100%;
  justify-content: space-between;
}

.copyright {
  flex-direction: column;
  font-size: $font-x-small;
}

.contact__us,
.powered {
  display: flex;
  align-items: center;

  svg {
    width: 2rem;
    height: 2rem;
    margin: 0.3rem;
    margin-bottom: 0;
  }
}

.logo {
  align-items: center;
  display: flex;
  color: $white;
  font-size: $font-x-small;
  margin-bottom: 0.4rem;

  svg path {
    fill: $white;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.contact__us {
  align-items: center;

  .contact__us__title {
    font-size: $font-small;
    margin-right: 0.3rem;
  }
}

.contact__us__item {
  display: flex;
  align-items: center;
  font-size: $font-x-small;

  a {
    display: flex;
    align-items: center;
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

@media only screen and (max-width: $breakpoint-mobile-large) {
  .container {
    flex-direction: column;
  }

  .copyright,
  .contact__us,
  .powered,
  .logo {
    display: flex;
    margin: 1.25rem 0;
    justify-content: center;
    text-align: center;
  }

  .logo {
    margin-bottom: 0.1rem;
  }
}
