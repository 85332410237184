@import '../../../../sass/variables';

.container {
  @apply sm:w-2/3 sm:m-auto mx-4 bg-white rounded-lg;

  box-shadow: $shadow;
  padding: 4rem 2rem 2rem 2rem;
  margin-top: 6rem;
  position: relative;

  .image-upload {
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50%, -50%);
    width: 10rem;

    .image {
      background-color: #fff;
      box-shadow: 0px 4px 11px 0px #0000002e;
      border-radius: 50%;
      height: 10rem;
      object-fit: cover;
      width: 10rem;
    }

    .edit {
      align-items: center;
      background-color: #73b8b2;
      border-radius: 50%;
      box-shadow: 0px 4px 11px 0px #0000002e;
      display: flex;
      height: 3rem;
      justify-content: center;
      left: 80%;
      position: absolute;
      top: 80%;
      transform: translate(-50%, -50%);
      width: 3rem;

      &__label {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;
      }

      &__input {
        display: none;
      }

      &__icon {
        color: #ffffff;
        height: 1.5rem;
        width: 1.5rem;

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.error {
  @apply text-red-600;

  text-align: center;
}
