@import 'sass/variables';

.container {
  @apply bg-white p-8 rounded-lg;

  box-shadow: $shadow;
  position: relative;
  max-width: 30rem;
  cursor: pointer;
  
  .options {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: absolute;
    top: 1rem;
    right: 1rem;

    .status {
      border-radius: 0.5rem;
      box-shadow: $shadow;
      font-size: 0.8rem;
      padding: 0.5rem;
    }

    .published {
      @extend .status;

      color: #5dc28e;
    }

    .rejected {
      @extend .status;

      color: #d22b2b;
    }
  }

  .image {
    @apply rounded-full;

    background-color: #fff;
    border-radius: 50%;
    box-shadow: $shadow;
    height: 6rem;
    min-width: 5rem;
    object-fit: cover;
    position: absolute;
    top: -2rem;
    left: -2rem;
    width: 6rem;
  }

  .title {
    @apply font-bold;

    font-size: 1.2rem;
    margin-top: 2rem;
  }

  .contact-info {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;

    .contact-item {
      align-items: center;
      display: flex;
      gap: 0.3rem;
    }
  }

  .description {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  @media screen and (max-width: $breakpoint-tablet) {
    .contact-info {
      flex-direction: column;
    }
  }
}
