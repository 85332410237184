@import 'sass/variables';

.button {
  width: 12rem;
  height: 2.3rem;
  border-radius: 0.5rem;
  font-size: $font-medium;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  cursor: pointer;

  span {
    padding-right: 0.2rem;
  }
}

.donation {
  background-color: $primary;
}

.petition {
  background-color: $secondary;
}

@media screen and (max-width: $breakpoint-mobile-large) {
  .button {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    font-size: $font-x-large;
    font-weight: $bold;

    span {
      display: none;
    }
  }
}
