// Colors
$primary: #FF2063;
$secondary: #73b8b2;
$black: #484242;
$complementary-1: #d79a9f;
$white: #fff;
$donation: #FF2063;
$request: #73b8b2;
$user: #000000;
$sign--out: #f2083f;
$whatsapp: #25d366;
$whatsapp--active: #25d366;
$facebook: #3b5998;
$facebook--active: #5c6d93;
$telegram: #3ea8e2;
$telegram--active: #72bce4;
$twitter: #1c93e4;
$twitter--active: #56a5da;
$email: #d24538;
$email--active: #d48179;
$reddit: #f24100;
$reddit--active: #f28259;
$footer-background: #353434;
$grey-text: #bcbcbc;

// Shadows
$shadow: 0px 4px 11px 0px #0000002e;

// Breakpoints
$breakpoint-tablet: 48rem;
$breakpoint-mobile-large: 26.56rem;
$breakpoint-mobile-small: 20rem;

// Utilities
$header-height: 5rem;
$max-width: 67.5rem;

// Radius
$radius-full: 624.93rem;

// Fonts
$font-x-small: 0.75rem;
$font-small: 0.87rem;
$font-medium: 1rem;
$font-large: 1.12rem;
$font-x-large: 1.25rem;
$bold: 600;


// z-indexes
$z-0: 0;
$z-1: 1;
$z-2: 2;
$z-3: 3;
$z-4: 4;
$z-5: 5;
$z-6: 6;
$z-7: 7;
$z-8: 8;
$z-9: 9;
