@import 'sass/variables';

.user-info {
  @apply rounded-lg;

  background-color: $primary;
  display: flex;
  align-items: center;
  padding: 1.125rem 2rem;

  .avatar {
    margin-right: 1rem;

    img {
      background-color: #fff;
      border-radius: 9999px;
      height: 5rem;
      object-fit: cover;
      width: 5rem;
    }
  }

  .wrapper {
    color: #fff;

    .greeting {
      @apply font-semibold;

      font-size: 1.125rem;
    }

    .edit-profile {
      font-size: 0.8rem;

      .link {
        @apply font-semibold;

        margin-left: 0.3rem;
        text-decoration: underline;
      }
    }
  }
}

.container {
  padding-inline: 0.5rem;
  margin-top: 1.5rem;
  overflow: hidden;
}

.petitions-container {
  @extend .container;

  padding-inline: 2.5rem;
  padding-top: 2rem;
}

.see-more {
  color: $secondary;
  font-size: 1.5rem;
  height: 100%;
  vertical-align: center;
  text-decoration: underline;
}

@media screen and (max-width: $breakpoint-mobile-large) {
  .user-info {
    flex-direction: column;

    .wrapper {
      margin-top: 1rem;
      text-align: center;
    }
  }

  .see-more {
    font-size: $font-x-large;
  }
}

.slide {
  height: unset !important;
  width: unset !important;
}
