@import 'sass/variables';

.overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.79);
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-8;
}

.container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: auto;
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-9;

  .content {
    @apply bg-white;

    box-shadow: $shadow;
    height: fit-content;
    margin: auto;
    min-width: 23.75rem;
    padding: 20px;
    width: 30%;
    border-radius: 30px;
  }

  @media screen and (max-width: $breakpoint-tablet) {
    .content {
      min-width: 13.75rem;
      width: 80%;
    }
  }

  @media screen and (max-width: $breakpoint-mobile-large) {
    .content {
      width: 100%;
    }
  }
}
