@import 'sass/variables';

.container {
  position: fixed;
  height: 100vh;
  width: 100%;

  .child {
    position: absolute;
    z-index: $z-9;
  }

  .child:nth-of-type(1) {
    top: 15%;
    left: -5%;
  }

  .child:nth-of-type(2) {
    top: 5%;
    right: -5%;
  }

  .child:nth-of-type(3) {
    top: 30%;
    left: 55%;
  }

  .child:nth-of-type(4) {
    top: 70%;
    left: 5%;
  }

  .child:nth-of-type(5) {
    top: 80%;
    right: -5%;
  }

  @media screen and (max-width: $breakpoint-tablet) {
    .child:nth-of-type(2) {
      top: 0;
      right: -25%;
    }
  }

  @media screen and (max-width: $breakpoint-mobile-large) {
    .child:nth-of-type(1) {
      top: 5%;
      left: -15%;
    }

    .child:nth-of-type(2) {
      top: -10%;
      right: -50%;
    }

    .child:nth-of-type(3) {
      top: 40%;
      left: 75%;
    }

    .child:nth-of-type(4) {
      top: 60%;
      left: -30%;
    }
  }

  @media screen and (max-width: $breakpoint-mobile-small) {
    .child:nth-of-type(4) {
      top: 60%;
      left: -50%;
    }
  }
}
