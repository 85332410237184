@import '../../../../sass/variables';

.modal__content,
.user__info,
.user__contact__info,
.petition__info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin-top: 0.6rem;
}

.modal__content {
  margin: 1.2rem;
  text-align: center;
  color: $black;
}

.user__info {
  align-items: center;

  img {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    object-fit: cover;
  }

  span {
    margin-top: 1rem;
    font-size: $font-x-large;
    font-weight: 700;
  }
}

.petition__info {
  margin-top: 1rem;

  div {
    display: flex;
    justify-content: center;
  }

  .petition__subject {
    width: 100%;
    font-weight: 500;
    font-size: $font-x-large;
    line-height: 2rem;
    text-align: start;
  }

  .petition__description {
    margin-top: 0.6rem;
    font-weight: 400;
    font-size: $font-medium;
    max-width: 31rem;
    text-align: start;
    line-height: 2rem;
    font-weight: 400;
    max-height: 18.7rem;
    overflow: hidden;
    overflow-y: scroll;
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
    scroll-padding-left: 1rem;
  }

  ::-webkit-scrollbar-thumb {
    background: $request;
    border-radius: 0.5rem;
  }

  ::-webkit-scrollbar-track:hover {
    box-shadow: inset 0 0 0.1rem grey;
    border-radius: 0.6rem;
  }
}

.user__contact__info {
  flex-direction: row;
  margin-top: 1.2rem;
  justify-content: space-around;
  width: 70%;

  span {
    display: flex;
    align-items: center;
    margin: auto;
    font-size: $font-medium;

    svg {
      margin-right: 1rem;
      height: 1rem;
      width: 1rem;
    }

    .email__icon {
      margin-left: 1.2rem;
    }
  }
}

.close {
  color: $primary;
  margin-top: 1.8rem;
}

.loading {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: $breakpoint-tablet) {
  .petition__info {
    p {
      font-size: $font-x-small;
    }
  }

  .user__contact__info {
    span {
      font-size: $font-x-small;
    }

    svg {
      margin-right: 0.5rem;
    }
  }
}
