@import '../../../../sass/variables';

.user-container {
  @apply flex flex-col gap-4 md:flex-row;

  align-items: center;

  .user-image {
    display: flex;
    align-items: center;

    img {
      @apply rounded-full;

      background-color: #fff;
      border-radius: 50%;
      box-shadow: $shadow;
      height: 10rem;
      min-width: 10rem;
      object-fit: cover;
      width: 10rem;
    }
  }

  .user-info {
    @apply flex flex-col gap-4 p-4;

    width: 100%;

    .name-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 100%;

      .user-name {
        font-weight: 500;
        font-size: 1.875rem;
        line-height: 2.25rem;
        padding-block: 5px;
      }
    }

    .contact-info {
      display: flex;
      gap: 1rem;
      margin-top: 1rem;

      .contact-item {
        align-items: center;
        display: flex;
        gap: 0.3rem;
      }
    }

    @media screen and (max-width: $breakpoint-tablet) {
      .contact-info {
        flex-direction: column;
      }
    }
  }
}

.link {
  color: $secondary;
}
