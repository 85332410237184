@import 'sass/variables';

.header {
  align-items: center;
  background-color: $white;
  box-shadow: 0px 4px 11px 0px #73b8b233;
  display: flex;
  height: $header-height;
  justify-content: center;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: $z-7;

  .header_content,
  .header_logo a,
  .nav,
  .nav-account {
    display: flex;
    align-items: center;

    .avatar {
      border-radius: $radius-full;
      height: 3.5rem;
      object-fit: cover;
      width: 3.5rem;
    }
  }

  .header_content {
    align-items: center;
    display: flex;
    max-width: $max-width;
    justify-content: space-between;
    padding: 0 1.6rem;
    width: 100%;
  }

  .nav_donation-request {
    align-items: center;
    display: flex;
    gap: 1rem;
    margin-right: 5rem;
    max-width: 250px;
    justify-content: space-between;
  }

  .title {
    align-items: baseline;
    color: $black;
    display: flex;
    font-size: $font-large;
    font-weight: $bold;

    .icon--active {
      transform: rotate(180deg);
    }
  }

  img {
    margin-left: 10px;
  }

  .title svg {
    margin-left: 6px;
  }
}

button {
  display: flex;
  align-items: center;
}

.sub-nav {
  position: relative;
}

.nav__dropdown {
  background-color: $white;
  border-radius: 14px;
  box-shadow: $shadow;
  flex-direction: column;
  position: absolute;
  margin-top: 1rem;
  padding: 0.6rem;
  right: 0;
}

.nav-link {
  display: flex;
  align-items: center;
  padding: 10px 21px 10px 21px;
  border-radius: 5px;
  width: 200px;
  justify-content: flex-end;
}

.donation {
  border: 1px solid $donation;
  color: $donation;

  .nav-link:hover {
    color: $white;
    background-color: $donation;
  }

  .nav-link--active {
    background-color: $donation;
  }
}

.request {
  border: 1px solid $request;
  color: $request;

  .nav-link:hover {
    color: $white;
    background-color: $request;
  }

  .nav-link--active {
    background-color: $request;
  }
}

.user {
  border: 1px solid $user;
  color: $user;

  svg {
    margin-left: 10px;
    width: 25px;
  }

  button {
    width: 100%;
  }

  .nav-link:hover {
    color: $white;
    background-color: $user;

    svg path {
      fill: $white;
    }

    svg {
      width: 25px;
    }
  }

  .nav-link_sign-out:hover {
    background-color: $sign--out;
  }

  .nav-link--active {
    background-color: $user;
  }
}

.nav-link--active {
  color: white;
  margin-block: 3px;
}

.mobile-menu {
  display: none;
}

@media only screen and (max-width: 768px) {
  .header {
    @apply lg:p-7 bg-white;

    box-shadow: 0px 4px 11px 0px #73b8b233;
    display: flex;
    padding: 1rem 0.5rem;
    position: sticky;
    top: 0;

    .header_content {
      display: flex;
      justify-content: space-between;
      padding: 0 0.5rem;
      width: 100%;
    }

    .title {
      @apply lg:text-2xl font-bold;

      color: $black;
    }
  }

  .mobile-menu {
    display: flex;
    position: relative;

    &__button {
      @apply z-10;
    }

    &__dropdown {
      @apply absolute right-0 mt-2 w-48 bg-white rounded-md shadow-xl z-20 py-5 px-2;

      margin-top: 30px;
      border: 1px solid $black;
    }
  }

  .mobile-menu .nav-link {
    @apply block px-4 py-2 rounded mt-1 w-full text-left;

    color: $black;
  }

  .nav-link:hover {
    color: $white;
  }

  .donation:hover {
    color: $white;
    background-color: $donation;
  }

  .donation {
    &--active {
      color: white;
      background-color: $donation;
    }
  }

  .request:hover {
    color: $white;
    background-color: $request;
  }

  .request {
    &--active {
      background-color: $request;
    }
  }

  .user,
  .donation,
  .request {
    border: none;
  }

  .user:hover {
    background-color: $user;
  }

  .user {
    &--active {
      background-color: $user;
    }
  }

  .nav-link_sign-out:hover {
    background-color: $sign--out;
  }

  .nav-link {
    &--active {
      color: $white !important;
    }
  }

  .header .nav {
    display: none;
  }
}

.unauthenticated-nav {
  display: flex;
  gap: 1rem;

  button {
    border: unset !important;
    margin-top: 0;
    width: 12rem;
  }

  @media screen and (max-width: $breakpoint-tablet) {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
}
