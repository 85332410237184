@import 'sass/variables';

.container {
  @apply p-6 rounded-lg bg-white;

  box-shadow: $shadow;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  max-width: 20rem;
  position: relative;

  .options {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: absolute;
    top: 1rem;
    right: 1rem;

    .status {
      background-color: $white;
      border-radius: 0.5rem;
      box-shadow: $shadow;
      font-size: 0.8rem;
      padding: 0.5rem;
    }

    .published, .accepted {
      @extend .status;

      color: #5dc28e;
    }

    .rejected {
      @extend .status;

      color: #d22b2b;
    }

    .pending {
      @extend .status;

      color: #f8bb11;
    }
  }

  .image {
    @apply object-cover rounded-lg w-full;

    max-height: 200px;
  }

  .title {
    @apply font-bold text-lg;
  }

  .description {
    @apply mt-4;

    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  .see-more-container {
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;

    .see-more {
      align-self: flex-end;
      align-items: center;
      background-color: $primary;
      border-radius: 8px;
      color: $white;
      display: flex;
      gap: 0.5rem;
      padding: 0.4rem 1.8rem;
    }
  }
}
