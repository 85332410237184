@import 'sass/variables';

.container {
  @apply sm:m-auto mx-4 rounded-lg;

  box-shadow: $shadow;
  background-color: $white;
  padding: 2rem;
  margin-top: 3rem;

  .inputs {
    align-items: center;
    display: flex;
    gap: 2rem;

    .info {
      width: 60%;

      textarea {
        min-height: 7rem;
      }
    }
  }
}

.image-upload {
  position: relative;
  max-height: 15rem;
  width: 40%;

  &__container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__image {
    @apply w-full object-cover object-center rounded-2xl;

    max-height: 15rem;
  }

  &__button {
    @apply rounded-lg bg-white py-3 px-8 font-semibold;

    color: $primary;
    border-color: $primary;
    border-width: 2px;

    &:hover {
      @apply text-white;

      background-color: $primary;
      cursor: pointer;
      transition: all 0.2s ease-in;
    }
  }

  &__input {
    display: none;
  }

  .error {
    @apply text-red-600;
  }
}

@media (max-width: $breakpoint-mobile-large) {
  .container {
    padding: 1rem;

    .inputs {
      flex-direction: column;

      .info {
       width: 100%;
      }
    }
  }

  .image-upload {
    width: 100%;
  }
}
