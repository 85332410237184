@import 'sass/variables';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;

  .not-found {
    color: $primary;
    font-size: 15rem;
    font-weight: bold;
  }

  .caption {
    margin-left: 1rem;
    font-size: 1.5rem;
  }

  @media (max-width: $breakpoint-mobile-large) {
    .not-found {
      font-size: 8rem;
    }

    .caption {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}
