@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

footer,
header {
  flex-shrink: 0;
}

main {
  flex-grow: 1;
}


.swiper {
  overflow: visible !important;
  padding: 0 0 4rem !important;
}